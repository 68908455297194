import { useTranslation } from 'react-i18next';
import { CarrierTier } from 'shared/enums/carrier-tier.enum';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { IsCarrierRestrictedOnLoad } from 'app/util/loads/cap-restricted-function';
import { getScheduledReleaseDateTime } from 'app/util/loads/scheduled-release-function';
import { useCarrierDetails } from 'app/hooks/store/use-carrier-details.hook';
import { LoadReleaseScheduleTimer } from '../load-release-schedule/load-release-schedule-timer.component';
import { useState } from 'react';

import './cap-description.component.scss';

interface Props {
  loadCarrierTier?: string;
  openToCoreDateTime?: Date;
  openToBaseDateTime?: Date;
}

export const CapDescription = ({ loadCarrierTier, openToCoreDateTime, openToBaseDateTime }: Props) => {
  const { enableLoadDetailsShotClock } = useFlags();
  const { t } = useTranslation();
  const [timerElapsed, setTimerElapsed] = useState(false);
  const carrierCapCode = useCarrierDetails()?.capCode;
  const upperLoadTier = (loadCarrierTier ?? 'None').toUpperCase();

  const isKeyLoad: boolean = upperLoadTier == CarrierTier.KEY;

  // If flag is on, calculate timer value
  const scheduledReleaseDateTime: Date = enableLoadDetailsShotClock ?
    getScheduledReleaseDateTime(loadCarrierTier, carrierCapCode, openToCoreDateTime, openToBaseDateTime) : undefined;

  if (IsCarrierRestrictedOnLoad(upperLoadTier, carrierCapCode) || ![CarrierTier.KEY, CarrierTier.CORE].includes(CarrierTier[upperLoadTier])) {
    return;
  }

  return <div className={`${loadCarrierTier} cap-labeled`} data-testid="cap-label">
    {(enableLoadDetailsShotClock && scheduledReleaseDateTime) ? (
      timerElapsed ?
        <>
          {t("CAP_SCHEDULED_RELEASE_REFRESH")}
        </> :
        <div>
          <img className="cap-label-icon" src={isKeyLoad ? "/assets/images/key_orange.svg" : "/assets/images/stars_blue.svg"} data-testid="cap-label-image" />
          <b>{loadCarrierTier} Load </b>
          <LoadReleaseScheduleTimer
            loadReleaseSchedule={scheduledReleaseDateTime}
            loadCarrierTier={upperLoadTier}
            onTimeout={() => {
              setTimerElapsed(true);
            }}
          />
        </div>
    ) : (
      <>
        <img className="cap-label-icon" src={isKeyLoad ? "/assets/images/key_orange.svg" : "/assets/images/stars_blue.svg"} data-testid="cap-label-image" />
        <div>
          <span><b> {isKeyLoad ? t('KEY_LOAD') : t('CORE_EXCLUSIVE')}</b> {t('CAP_RELEASES')}</span>
        </div>
      </>
    )}
  </div>;
};
