import { Translation } from 'shared/components/translation/translation.component';
import React from 'react';
import { ReloadsPopover } from 'shared/reloads/reloads-more-info-popover.component';
import { ReloadsData } from 'pages/find-loads-ver2/hooks/use-reloads.hook';

import './reloads-title.component.scss';

interface TitleProps {
  reloadsData: ReloadsData;
}

export const ReloadsTitle: React.FC<TitleProps> = ({reloadsData}) =>
  <div className="title" role="heading" aria-level={3}>
    <span className="reloads-counter">
      {!!reloadsData?.numberOfReloads &&
        <Translation resource="RELOADS" replacements={[reloadsData?.numberOfReloads]}/>}
    </span>
    <span>
      <ReloadsPopover reloadsData={reloadsData}/>
    </span>
  </div>;
