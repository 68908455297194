import React from 'react';
import { useDispatch } from 'react-redux';
import * as analyticsActions from '@features/analytics/redux/analytics-v2.actions';

export const useReloadsAnalytics = () => {
  const dispatch = useDispatch();

  const dispatchSetReloadsSearchEvent = React.useCallback((reloadsEvent: analyticsActions.SetReloadsSearchEvent) => {
    dispatch(analyticsActions.setReloadsSearchEvent(reloadsEvent));
  }, []);
  return {
    dispatchSetReloadsSearchEvent,
  };
};
