import { CarrierTier } from 'shared/enums/carrier-tier.enum';

import './cap-locked.component.scss';

interface Props {
  carrierTier: string;
  variant: 'locked' | 'restricted';
}

export const CapLocked = ({ carrierTier, variant }: Props) => {
  const isKeyLoad: boolean = carrierTier.toUpperCase() == CarrierTier.KEY;

  if (variant == 'restricted') {
    return <div className="cap-locked" data-testid="cap-restricted">
      <img className="lock-icon" src="/assets/images/Lock.svg" data-testid="cap-icon" />
    </div>;
  }
  else {
    return <div className={`${carrierTier} cap-locked`} data-testid="cap-locked">
      <img className="lock-icon" src={isKeyLoad ? "/assets/images/lock_orange.svg" : "/assets/images/lock_blue.svg"} data-testid="cap-icon" />
      <span>{carrierTier} Load</span>
    </div>;
  }
};
