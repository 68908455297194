import { CarrierTier } from 'shared/enums/carrier-tier.enum';

export const IsCarrierRestrictedOnLoad = (loadTier: string, carrierTier: string) => {

  const upperLoadTier = loadTier?.toUpperCase();

  switch (carrierTier) {
    case CarrierTier.KEY:
      return false;
    case CarrierTier.CORE:
      return upperLoadTier == CarrierTier.KEY;
    case CarrierTier.BASE:
    default:
      return [CarrierTier.KEY, CarrierTier.CORE].includes(CarrierTier[upperLoadTier]);
  }
}
