import './shipment-notes.component.scss';

export interface ShipmentNotesProps {
  notes: shipmentNoteJSON[];
}

export const ShipmentNotes = ({ notes = [] }: ShipmentNotesProps) => {
  return (
    <div className="shipment-note-content">
      {notes.map((d, i) => (
        <p className="shipment-note" key={i}>
          {d.note}
        </p>
      ))}
    </div>
  );
};
