import { CityStateCountryFormatter } from 'shared/components/formatters/city-state-country.formatter';
import { Timing } from 'shared/components/loads/stops/timing.component';
import { Distance } from 'shared/components/formatters/conversion/conversion.components';
import { AvailableLoadLocationType } from 'shared/enums/available-loads/location-type.enum';
import { LoadSummaryAddress } from 'shared/models/loads/load-summaries/load-summary-address.model';
import { useTranslation } from 'react-i18next';
import TruncatedElement from 'shared/components/elements/truncated-element.component';
import './load-location.component.scss';

interface Props {
  address: LoadSummaryAddress;
  deadhead: number;
  startTime: Date;
  endTime: Date;
  showDeadhead: boolean;
  locationType: AvailableLoadLocationType;
  sectionName: string;
}

export const LoadLocation = ({ address, deadhead, startTime, endTime, showDeadhead, locationType, sectionName }: Props) => {
  const isOrigin = locationType === AvailableLoadLocationType.ORIGIN;
  const { t } = useTranslation();

  return (
    <div className={`${sectionName}-location js-load-${isOrigin ? 'origin' : 'destination'}`} data-testid='load-location'>
      <div className="location-deadhead-group">
        <div className="sr-only">{t(isOrigin ? 'ORIGIN_INFORMATION' : 'DESTINATION_INFORMATATION')}</div>
        <TruncatedElement maxLines={1} className="location-deadhead-address">
          <CityStateCountryFormatter address={address} forceTitleCasing={true} />
        </TruncatedElement>
        {showDeadhead &&
          <span className="help-text location-deadhead-distance">
            <div className="sr-only">{t(isOrigin ? 'DEADHEAD_DISTANCE_FROM_PICKUP' : 'DEADHEAD_DISTANCE_TO_DROP_OFF')}</div>
            <Distance value={deadhead} float={1} />
          </span>
        }
      </div>
      <div className="time help-text">
        <div className="sr-only">{t(isOrigin ? 'PICKUP_DATE' : 'DROP_OFF_DATE')}</div>
        <Timing
          withFlex={true}
          start={startTime}
          end={endTime}
        />
      </div>
    </div>
  );
};
