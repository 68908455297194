import { useTranslation } from 'react-i18next';
import { useTimer } from 'app/hooks/use-timer.hook';

interface LoadReleaseScheduleTimerProps {
  loadReleaseSchedule: Date;
  isTiered?: boolean;
  loadCarrierTier: string;
  userCarrierTier?: string;
  onTimeout: () => void;
}

export const LoadReleaseScheduleTimer = ({ loadReleaseSchedule, isTiered, loadCarrierTier, userCarrierTier, onTimeout }: LoadReleaseScheduleTimerProps) =>
  <LoadReleaseScheduleTimerComponent loadReleaseSchedule={loadReleaseSchedule} isTiered={isTiered} loadCarrierTier={loadCarrierTier} userCarrierTier={userCarrierTier} onTimeout={onTimeout} />;

const LoadReleaseScheduleTimerComponent = (({ loadReleaseSchedule, isTiered, loadCarrierTier, userCarrierTier, onTimeout }: LoadReleaseScheduleTimerProps) => {
  const { t } = useTranslation();

  const timeLeft = useTimer({
    timerExpirationDate: loadReleaseSchedule,
    onTimeout: () => {
      onTimeout();
    }
  });

  return (
    <>
      {isTiered ? t("CAP_SCHEDULED_RELEASE_TIER", { 0: userCarrierTier.slice(0, 1).toUpperCase() + userCarrierTier.slice(1).toLowerCase() }) : t("CAP_SCHEDULED_RELEASE")}
      <span className={`timer ${loadCarrierTier}`}><b>{timeLeft}</b></span>
    </>
  );
});