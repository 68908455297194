import { LoadStickyTitleBar } from './load-stick-title-bar/load-sticky-title-bar.component';
import { AvailableLoadError, AvailableLoadNotFound } from 'shared/models/loads/available-load-detail.model';
import { useTranslation } from 'react-i18next';

interface NotAvailableProps {
  loadError: AvailableLoadNotFound | AvailableLoadError;
  loadNumber: number;
  isLocked: boolean;
  handleFocusSelectedLoadResult?: () => void;
  handleOnClose?: () => void;
  isReload?: boolean;
}

export const NoAvailableLoadComponent = ({loadNumber, isLocked, loadError, handleOnClose, handleFocusSelectedLoadResult, isReload}: NotAvailableProps) => {
  const { t } = useTranslation();
  const showUnavailable = ((loadError && loadError instanceof AvailableLoadNotFound) || isLocked);
  const unavailableText = isReload ? 'THIS_RELOAD_IS_NO_LONGER_AVAILABLE' : 'THIS_LOAD_IS_NO_LONGER_AVAILABLE';
  const errorText = showUnavailable ? unavailableText : 'AN_ERROR_OCCURED_ATTEMPTING_TO_LOAD_THE_PAGE';
  const tryAnotherText = isReload ? 'PLEASE_TRY_ANOTHER_RELOAD' : 'TRY_A_DIFFERENT_LOAD_OR_REFRESH';

  return <>
    <LoadStickyTitleBar loadNumber={loadNumber} onClose={handleOnClose} focusSelectedLoadResult={handleFocusSelectedLoadResult}/>
    <div className="content-error">
      <p className='error-subject'>
        {t(errorText)}
      </p>
      <p className='error-recommendation'>{t(tryAnotherText)}</p>
    </div>
  </>;
};
