import classNames from 'classnames';
import { Stop } from 'shared/models/loads/stops/stop.model';
import { DriverWork } from 'shared/enums/driver-work.enum';
import { StopType } from 'shared/enums/stop-type.enum';
import { UnitOfMeasure } from 'shared/enums/unit-of-measure.enum';
import { AppointmentType } from 'shared/enums/appointment-type.enum';
import { CityStateCountryFormatter, AddressFormat } from 'shared/components/formatters/city-state-country.formatter';
import { Distance, Weight } from 'shared/components/formatters/conversion/conversion.components';
import { StopAppointment } from 'shared/components/loads/stop-details/stop-appointment/stop-appointment.component';
import { WarehouseHours } from 'shared/components/loads/stop-details/warehouse-hours/warehouse-hours.component';
import { useTranslation } from 'react-i18next';
import { Timing } from 'shared/components/loads/stops/timing.component';
import { Button } from 'shared/components/elements/button.component';
import { useFlags } from 'launchdarkly-react-client-sdk';

export interface StopDetailProps {
  stopDetail: Stop;
  sourceUnitOfMeasure: UnitOfMeasure;
  distance: number;
  onClickShrink?: () => void;
  pickStartTime: Date;
  pickEndTime: Date;
}

export const StopDetail = ({stopDetail, distance, sourceUnitOfMeasure, onClickShrink, pickStartTime, pickEndTime}: StopDetailProps) => {
  const { t } = useTranslation();
  const { findLoadsHideWarehouseHours } = useFlags();

  const startDateTime = stopDetail.isFirstStop()
      ? new Date(pickStartTime)
      : stopDetail.appointmentStart || new Date(stopDetail.calculatedArriveByStartDateTime);
    
  const endDateTime = stopDetail.isFirstStop()
      ? new Date(pickEndTime)
      : stopDetail.appointmentEnd || new Date(stopDetail.calculatedArriveByEndDateTime);

  return (
    <div className="stop">
      <div className="aside-section-left">
        <div className="time-line">
          <div className="step-bar"/>
        </div>
      </div>
      <div className="article-section">
        <div className="title" role="heading" aria-level={3}>
          {stopDetail.stopType === StopType.PICKUP 
            ? <span className="pickup-dropoff">{t('PICKUP')}</span>
            : <div>
              <span className="pickup-dropoff">{t('DROPOFF')}</span>{' '}
              <span>({<Distance value={distance} from={sourceUnitOfMeasure} />})</span>
            </div>
          }
        </div>
        <div className="warehouse-address">
          <div>{stopDetail.warehouse.name}</div>
          <CityStateCountryFormatter 
            address={stopDetail.warehouse.address}
            format={AddressFormat.CityState}
            forceTitleCasing={true}/>
        </div>
        <Timing hasSeparator start={startDateTime} end={endDateTime}/>
        <StopAppointment appointmentType={stopDetail.appointmentType || AppointmentType.CONTACT_CHR_FOR_APPOINTMENT_INFORMATION}
        />
        {!findLoadsHideWarehouseHours ?
          <>
            <div className="separator-line"/>
            <div className={classNames({'has-shrink': !!onClickShrink}, 'warehouse-hours')} data-testid='warehouse-hours'>
              {t('WAREHOUSE_HOURS')}: <span/>
              {stopDetail.warehouse.openingDateTime === null || stopDetail.warehouse.closingDateTime === null
                ? t('CONTACT_CHROBINSON')
                : <WarehouseHours start={stopDetail.warehouse.openingDateTime} end={stopDetail.warehouse.closingDateTime}/>
              }
            </div>
          </>
          : <div style={{paddingBottom:'22px'}}/>
        }      
        {!!onClickShrink &&
          <div className="shrink">
            <Button id="shrink-button" btnLink onClick={onClickShrink} data-testid="shrink-button">
              {t('VIEW_LESS')}
              <img src="/assets/images/arrow-expand.svg"/>
            </Button>
          </div>
        }
      </div>
      <div className="aside-section-right">
        <div>
          <span className="sr-only">{t('WEIGHT')}{' '}</span>
          <Weight
            value={stopDetail.actualWeight || stopDetail.maxWeight}
            from={sourceUnitOfMeasure}
          />
        </div>
        {stopDetail.driverWorkCode in DriverWork && (
          <div>
            <span className="sr-only">{t('DRIVER_WORK')}{' '}</span>
            {t(DriverWork[stopDetail.driverWorkCode])}
          </div>
        )}
      </div>
    </div>
  );
};
