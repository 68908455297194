import { useState } from 'react';
import { UnitOfMeasure } from 'shared/enums/unit-of-measure.enum';
import { Stop } from 'shared/models/loads/stops/stop.model';
import { StopDetail } from '@pages/find-loads-ver2/details/stop-details/stop-detail.component';
import { TranslationJSX } from 'shared/components/translation/translation.component';
import { useTranslation } from 'react-i18next';
import { Button } from 'shared/components/elements/button.component';

import './stop-details.component.scss';

export interface StopDetailsProps {
  stopDetails: Array<Stop>;
  sourceUnitOfMeasure: UnitOfMeasure;
  distance?: number;
  pickStartTime: Date;
  pickEndTime: Date;
}

export const StopDetails= ({ stopDetails, sourceUnitOfMeasure, distance, pickStartTime, pickEndTime }: StopDetailsProps) => {
  const { t } = useTranslation();
  const renderFirst = stopDetails?.length > 0;
  const renderMultiple = stopDetails?.length > 2;
  const renderLast = stopDetails?.length > 1;
  const [open, setOpen] = useState(false);
  const hiddenStops = stopDetails.slice(1, stopDetails.length - 1);
  const moreStopText = hiddenStops.length > 1 ? 'MORE_STOPS' : 'MORE_STOP';

  const renderStopDetail = (stop: Stop, onClick?: () => void) => (
    <StopDetail
      sourceUnitOfMeasure={sourceUnitOfMeasure}
      stopDetail={stop}
      key={stop.number}
      distance={distance}
      onClickShrink={onClick}
      pickStartTime={pickStartTime}
      pickEndTime={pickEndTime}
    />
  );

  const renderExpandCard = () => (
    <>
      <input type="checkbox" id="expand-check" checked={open} onChange={() => setOpen(!open)} />
      <div className="view-all">
        <div className="stop">
          <div className="aside-section-left">
            <div className="time-line">
              <div className="step-bar" />
            </div>
          </div>
          <div className="article-section">
            <div className="title">
              <TranslationJSX resource={moreStopText as ResourceKey} replacements={[<span>{hiddenStops.length}</span>]} />
            </div>
            <div>
              <Button
                id="expand-button"
                btnLink
                onClick={() => setOpen(true)}
              >
                {t('VIEW_ALL')}
                <img src="/assets/images/arrow-shrink.svg" />
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden-cards" data-testid="hidden-cards">
        {hiddenStops.map((item, index, array) => {
          const onclick = () => setOpen(false);
          return renderStopDetail(item, (index >= array.length-1) ? onclick : null);
        })}
      </div>
    </>
  );

  return (
    <div className="stop-details" data-testid="stop-details">
      {renderFirst && renderStopDetail(stopDetails[0])}
      {renderMultiple && renderExpandCard()}
      {renderLast && renderStopDetail(stopDetails[stopDetails.length -1])}
    </div>
  );
};
