import { AvailableLoadSummary } from 'shared/models/loads/load-summaries/available-load-summary.model';
import { Distance, Weight } from 'shared/components/formatters/conversion/conversion.components';
import { EquipmentMeasurements } from 'shared/components/formatters/equipment-measurements.formatter';
import { EquipmentTypeList } from 'shared/components/loads/equipment-types-list.component';
import { SpecializedEquipmentTypeList } from 'shared/components/loads/specialized-equipment-type-list.component';
import { EndorsementList } from 'shared/find-loads/components/endorsement-list.component';
import { TruncatedElement } from 'shared/components/elements/truncated-element.component';
import { useTranslation } from 'react-i18next';
import './load-location.component.scss';

interface Props {
  load: AvailableLoadSummary;
}

export const LoadRequirements = ({ load }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <div>
        <div className="js-load-distance no-wrap">
          <div className="sr-only">{t('DISTANCE')}</div>
          <Distance
            value={Math.ceil(load.distance)}
            from={load.measurementType}
          />
        </div>
        <div className="js-load-stop-count help-text no-wrap">
          <span>
            {load.pickCount} {t(load.pickCount > 1 ? 'PICKS' : 'PICK_LOWER')}, {load.dropCount} {t(load.dropCount > 1 ? 'DROPS' : 'DROP_LOWER')}
          </span>
        </div>
      </div>
      <div>
        <div className="js-load-weight no-wrap">
          <div className="sr-only">{t('WEIGHT')}</div>
          <Weight value={load.weight} from={load.measurementType} />
        </div>
        {load.hasEndorsements() &&
          <div className="js-load-endorsements">
            <div className="sr-only">{t('ENDORSEMENT')}</div>
            <EndorsementList load={load} />
          </div>
        }
      </div>
      <div className="load-equipment-type js-load-equipment-type">
        <div className="sr-only">{t('EQUIPMENT_TYPE')}</div>
        <TruncatedElement maxLines={2}>
          {load.hasSpecializedEquipment() ?
            <SpecializedEquipmentTypeList
              specializedCode={load.specializedEquipmentCode}
              equipmentCode={load.equipmentCode}
            />
            :
            <EquipmentTypeList
              equipmentCode={load.equipmentCode} />
          }
        </TruncatedElement >
        <div className="help-text">
          <div className="sr-only">{t('EQUIPMENT_SIZE')}</div>
          <EquipmentMeasurements item={load} />
        </div>
      </div>
    </>
  );
};
