import { OfferStatus } from 'shared/enums/offer-status.enum';
import { SortOrder } from 'shared/enums/sort-order.enum';

export namespace OFFER_STATUS {
  export const ERROR = 'ERROR';
  export const MAXIMUM_NUMBER_OF_OFFERS_SUBMITTED = 'MAXIMUM_NUMBER_OF_OFFERS_SUBMITTED';
  export const UNABLE_TO_PROCESS = 'UNABLE_TO_PROCESS';
  export const NOT_CONSIDERED = 'NOT_CONSIDERED';

  export const convertToFindLoadsOfferStatus = (offerStatus: OfferStatus): string => {
    switch (offerStatus) {
      case OfferStatus.MAXIMUM_NUMBER_OF_OFFERS_SUBMITTED: return OFFER_STATUS.MAXIMUM_NUMBER_OF_OFFERS_SUBMITTED;
      case OfferStatus.ERROR: return OFFER_STATUS.ERROR;
      default: throw new Error(`${OfferStatus[offerStatus]} is not currently supported for conversion to find loads offer status`);
    }
  };
}

export const sortOrderFieldDefaults: DictionaryJSON<SortOrder> = {
  ['originStateProvinceCode']: SortOrder.ASC,
  ['pickStartTimeV2']: SortOrder.ASC,
  ['destinationStateProvinceCode']: SortOrder.ASC,
  ['deliverByDateTime']: SortOrder.ASC,
  ['weight']: SortOrder.ASC,
  ['distance']: SortOrder.DESC,
  ['equipmentCode']: SortOrder.ASC,
  ['endorsementsList']: SortOrder.ASC,
  ['totalCost']: SortOrder.DESC,
  ['originDeadhead']: SortOrder.ASC,
  ['destinationDeadhead']: SortOrder.ASC,
  ['recommendedFieldColumn']: SortOrder.DESC
};
