import { useResource } from 'app/hooks/store/use-resource.hook';

import './contact-phone.component.scss';

declare interface ContactPhoneProps {
  bookingContactPhoneNumber?: string;
}

export const ContactPhone = (props: ContactPhoneProps) => {
  const contactCHRLabel = useResource('CONTACT_CHR');
  const phoneNumber =  props.bookingContactPhoneNumber ?? '';
  const phoneNumAriaLabel = `${contactCHRLabel}: ${phoneNumber}`;

  return (
    <div className='contact-phone'>
      <span className="glyphicon glyphicon-earphone" />
      &nbsp;
      <a href={`tel:${phoneNumber}`} aria-label={phoneNumAriaLabel} data-testid="contact-phone-link">{phoneNumber}</a>
    </div>
  );
}
