import { KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
    reloadsCount?: number;
}

export const ReloadsHeaderLink = ({reloadsCount}: Props) => {
    const { t } = useTranslation();
    const allReloadsHeaders = document.querySelectorAll('.reloads-section');
    const reloadsHeader = allReloadsHeaders?.item(allReloadsHeaders?.length - 1);  // Get last instance of reloads section because smaller screen sizes still have the sidebar one
    const hasReloadsSection = !!reloadsHeader && reloadsCount > 0;

    const jumpToReloads = () => hasReloadsSection && reloadsHeader?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    const keyDownJump = (e: KeyboardEvent<HTMLSpanElement>) => (e.key === 'Enter' || e.key === ' ' || e.key === 'Space Bar') && jumpToReloads();

    if (!hasReloadsSection) {
        return <></>;
    }
    return (
        <span className="reloads-link" onClick={jumpToReloads} onKeyDown={keyDownJump} tabIndex={0} data-testid="reloadsLink">
            {t('RELOADS', {0:reloadsCount})}
            <span className="arrow-down-icon"/>
        </span>
    );
};