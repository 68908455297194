import { CarrierTier } from 'shared/enums/carrier-tier.enum';

export const getScheduledReleaseDateTime = (loadTier: string, carrierTier: string, openToCoreDateTime: Date, openToBaseDateTime: Date) => {
  const upperLoadTier = loadTier?.toUpperCase();
  const upperCarrierTier = carrierTier?.toUpperCase();

  switch (upperLoadTier) {
    case CarrierTier.KEY:
      return upperCarrierTier === CarrierTier.BASE ?
        openToBaseDateTime : openToCoreDateTime;
    case CarrierTier.CORE:
      return openToBaseDateTime;
    default:
      return undefined;
  }
};