import { Button } from 'shared/components/elements/button.component';
import { useTranslation } from 'react-i18next';
import { ReloadsHeaderLink } from 'pages/find-loads-ver2/details/load-stick-title-bar/reloads-header-link.component';
import './load-sticky-title-bar.component.scss';

interface OwnProps {
  loadNumber: number;
  onClose?: () => void;
  focusSelectedLoadResult?: () => void;
  reloadsCount?: number;
  isReloadsSectionRendered?: Boolean;
}

export const LoadStickyTitleBar = (props: OwnProps) => {
  const { loadNumber, onClose, focusSelectedLoadResult, reloadsCount, isReloadsSectionRendered } = props;
  const { t } = useTranslation();

  return (
    <div className="title-bar">
      <h2>
        <span>#{loadNumber}</span>
        {isReloadsSectionRendered &&
          <ReloadsHeaderLink reloadsCount={reloadsCount} />
        }
      </h2>
      {onClose &&
        <Button
          className="close-button btn-clear"
          onClick={onClose}
          data-dismiss="modal"
          data-testid="closeButton"
        >
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="#222222" />
          </svg>
        </Button>
      }
      {!onClose &&
        <Button 
          btnLink 
          className="load-detail-sr-navigation 
          display-on-focus" 
          data-testid="backButton" 
          onClick={focusSelectedLoadResult}>
            {t('RETURN_TO_RESULTS')}
        </Button>
      }
    </div>
  );
};
