import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback, MouseEvent } from 'react';
import { useDispatch } from 'react-redux';
import { LoaderNames, RELOAD_DETAILS_MODAL } from 'app/globals/constants';
import { useLoader } from 'app/hooks/use-loader.hook';
import { useLoadsSearchState } from 'pages/find-loads-ver2/hooks/use-loads-search-state.hook';
import { useReloads } from 'pages/find-loads-ver2/hooks/use-reloads.hook';
import { Button } from 'shared/components/elements/button.component';
import { LoadingIndicatorSpinner } from 'shared/components/loading/inline-loaders/loading-indicator-spinner.component';
import { useModalState } from 'shared/components/modal/hooks/use-modal-state.hook';
import { hideModal, showModal } from 'shared/components/modal/modal.actions';
import { Modal } from 'shared/components/modal/modal.component';
import { ReloadsType } from 'shared/enums/reloads-type.enum';
import { ReloadResults } from 'shared/reloads/reload-results/reload-results.component';
import { ReloadsTitle } from 'shared/reloads/reloads-title.component';
import { AvailableLoadSummary } from 'shared/models/loads/load-summaries/available-load-summary.model';
import './reloads-view-all-modal.scss';

interface Props {
  reloads: AvailableLoadSummary[];
  primalLoadNumber: number;
}

export const VIEW_ALL_RELOADS = 'VIEW_ALL_RELOADS';

const ViewAllReloadsContent = ({ reloads, primalLoadNumber }: Props) => {
  const loader = useLoader(LoaderNames.FindLoadsVer2AllReloads);
  const { performedSuggestedLoadSearch } = useLoadsSearchState();
  const dispatch = useDispatch();
  const { preBookReloadDetails } = useFlags();
  const reloadClickHandler = (_: MouseEvent, clickedLoad: AvailableLoadSummary) => {
    dispatch(showModal(RELOAD_DETAILS_MODAL, {
      load: clickedLoad,
      reloadData: {
        primalLoadNumber,
        callVariation: performedSuggestedLoadSearch ? 'PreBookSplashPage' : 'PreBook',
        reloadNumber: clickedLoad.number
      }
    }));
  };

  if (loader.visible) {
    return <LoadingIndicatorSpinner mainText="LOADING___" isVisible={loader.visible} />;
  }

  if (!reloads) {
    return null;
  }

  return (
    <ReloadResults
      primalLoadNumber={primalLoadNumber}
      reloads={reloads}
      callVariation={performedSuggestedLoadSearch ? 'PreBookSplashPage' : 'PreBook'}
      onReloadClick={preBookReloadDetails ? reloadClickHandler : null}
      totalReloads={reloads.length}
    />
  );
};

export const ViewAllReloadsModal = () => {
  const dispatch = useDispatch();

  const loadsSearchState = useLoadsSearchState();
  const { context, visible } = useModalState(VIEW_ALL_RELOADS);
  const reloadsData = useReloads(
    visible ? context?.load : null,
    loadsSearchState,
    ReloadsType.PreBookReloads,
    LoaderNames.FindLoadsVer2AllReloads
  );

  const hideViewAllModal = useCallback(() => dispatch(hideModal(VIEW_ALL_RELOADS)), [dispatch]);
  const content = useCallback(
    () => !!context?.load?.number &&
      <ViewAllReloadsContent reloads={reloadsData?.reloads} primalLoadNumber={context.load.number} />,
    [reloadsData?.reloads, context?.load?.number]
  );
  const header = useCallback(() => <ReloadsTitle reloadsData={reloadsData} />, [reloadsData?.reloads]);

  return <Modal
    name={VIEW_ALL_RELOADS}
    header={header}
    children={content}
    footer={<Button className="js-ok-button" btnPrimary mobileResponsive onClick={hideViewAllModal} resource="OK" />}
  />;
};

export default ViewAllReloadsModal;
