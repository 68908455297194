import { AvailableLoadSummary } from 'shared/models/loads/load-summaries/available-load-summary.model';
import { LoadLocation } from '@pages/find-loads-ver2/results/loads/load-location.component';
import { AvailableLoadLocationType } from 'shared/enums/available-loads/location-type.enum';
import { LoadRequirements } from '@pages/find-loads-ver2/results/loads/load-requirements.component';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

interface Props {
  load: AvailableLoadSummary;
  showDestinationDeadhead: boolean;
  showOriginDeadhead: boolean;
  sectionName: string;
}

export const LoadCard = ({ load, showDestinationDeadhead, showOriginDeadhead, sectionName }: Props) => {
  return (
    <>
      <div className={`${sectionName}-section ${sectionName}-locations`} data-testid='load-locations'>
        <LoadLocation
          address={load.getOriginAddress()}
          deadhead={load.originDeadhead}
          startTime={load.pickStartTimeV2}
          endTime={load.pickEndTimeV2}
          showDeadhead={showOriginDeadhead}
          locationType={AvailableLoadLocationType.ORIGIN}
          sectionName={sectionName}
        />
        <div className="arrow">
          <ArrowForwardIcon sx={{ color: '#888888' }} />
        </div>
        <LoadLocation
          address={load.getDestinationAddress()}
          deadhead={load.destinationDeadhead}
          startTime={load.dropStartTimeV2}
          endTime={load.dropEndTimeV2}
          showDeadhead={showDestinationDeadhead}
          locationType={AvailableLoadLocationType.DESTINATION}
          sectionName={sectionName}
        />
      </div>
      <div className={`${sectionName}-section ${sectionName}-requirements`} data-testid='load-requirements'>
        <LoadRequirements load={load} />
      </div>
    </>
  );
};
