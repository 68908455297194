import { LoadSummaryCarrierOffer } from "shared/models/loads/load-summaries/load-summary-carrier-offer";
import { OfferStatus as OfferStatusEnum } from 'shared/enums/offer-status.enum';
import { Offer } from "shared/models/offers/offer.model";

export function loadSummaryCarrierOfferMapper(offer: Offer): LoadSummaryCarrierOffer {
  if (!offer) {
    return null;
  }
  const offerStatus: string = offer?.displayStatus ? OfferStatusEnum[offer.displayStatus] : OfferStatusEnum[OfferStatusEnum.UNKNOWN];
  const createdDateTime: string = offer?.enteredDateAdjustedTimeZone?.toISOString();

  const loadSummaryCarrierOffer: LoadSummaryCarrierOffer = {
    carrierCode: offer.carrierCode,
    loadNumber: offer.loadNumber,
    latestOffer: {
      acceptedDateTime: offer.displayStatus == OfferStatusEnum.ACCEPTED ? createdDateTime : null,
      createdDateTime: createdDateTime,
      acceptedByDataScience: offer.acceptedByDataScience,
      offerType: offer.offerType,
      currencyCode: offer.currencyCode,
      offerStatus: offerStatus,
      price: offer.offerPrice,
      rejectionReason: offer.rejectionReason,
      offerId: offer.offerId,
      offerRequestId: offer.offerRequestId,
      isFinalNegotiation: !offer.isShipmentOfferable && !offer.isShipmentCounterOfferable,
    }
  };
  return loadSummaryCarrierOffer;
}
