import { useLoader } from 'app/hooks/use-loader.hook';
import {
  LoadingIndicatorSpinner
} from 'shared/components/loading/inline-loaders/loading-indicator-spinner.component';
import { AvailableLoadSummary } from 'shared/models/loads/load-summaries/available-load-summary.model';
import { ReloadResults } from 'shared/reloads/reload-results/reload-results.component';
import { ReloadsTitle } from 'shared/reloads/reloads-title.component';
import React from 'react';
import './reloads.compoment.scss';
import { ReloadsData } from 'pages/find-loads-ver2/hooks/use-reloads.hook';
import { useEffect } from 'react';

interface Props {
  reloadsData: ReloadsData;
  loaderName: string;
  onReloadClick?: (e: React.MouseEvent, selectedReload?: AvailableLoadSummary) => void;
  onViewAllClick?: (e: React.MouseEvent) => void;
  callVariation: ReloadsCallVariation;
  primalLoadNumber: number;
  setIsReloadsSectionRendered?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Reloads = ({
  reloadsData,
  loaderName,
  onReloadClick,
  onViewAllClick,
  callVariation,
  primalLoadNumber,
  setIsReloadsSectionRendered
}: Props) => {
  const loader = useLoader(loaderName);
  const hasReloads = !!reloadsData?.reloads?.length;

  useEffect(() => {
    if (hasReloads && setIsReloadsSectionRendered) {
      setIsReloadsSectionRendered(true);
    }
  }, [hasReloads])

  if (loader.visible) {
    return (
      <div className="reloads-section">
        <LoadingIndicatorSpinner mainText="LOADING___" isVisible={loader.visible}/>
      </div>
    );
  }

  return (
  hasReloads && <div className="reloads-section">
    <div className="separator-line"/>
    <ReloadsTitle reloadsData={reloadsData}/>
      <ReloadResults reloads={reloadsData?.reloads}
        onReloadClick={onReloadClick}
        onViewAllClick={onViewAllClick}
        callVariation={callVariation}
        primalLoadNumber={primalLoadNumber}
        totalReloads={reloadsData.numberOfReloads}/>
    </div>
  );
};
