import React from 'react';
import { useState, useRef, useLayoutEffect } from 'react';
import { useOnWindowResize } from 'app/hooks/use-on-window-resize.hook';

interface Props {
  maxLines?: number;
  className?: string;
  ariaLabel?: string;
  children: JSX.Element;
}

/*
  The parent container must have a width or max width set in order for truncation to occur.
  If this is inside a flex child, that flex child container should have "min-width: 0" set on it.
*/
export const TruncatedElement: React.FC<Props> = ({ maxLines, className, ariaLabel, children }) => {
  const divRef = useRef<HTMLDivElement>();
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [elementText, setElementText] = useState<string>();

  useOnWindowResize(() => checkOverflowing());
  useLayoutEffect(() => checkOverflowing(), [divRef]);

  const checkOverflowing = () => {
    if (divRef.current) {
      const div = divRef.current;
      const divIsOverflowing = div.scrollHeight > div.clientHeight || div.scrollWidth > div.clientWidth;
      setElementText(div.innerText);
      setIsOverflowing(divIsOverflowing);
    }
  }

  if (!children) {
    return null;
  }

  const maxLinesClass = `max-lines-${maxLines ?? 1}`;
  return (
    <div
      className={`truncate ${maxLinesClass} ${className || ''}`}
      ref={divRef}
      title={isOverflowing ? elementText : ''}
      aria-label={ariaLabel}
    >
      {children}
    </div>
  );
}

export default TruncatedElement;
