import { Offer } from "shared/models/offers/offer.model";

export const isOfferExpired = (offer: Offer): boolean => {
  const expirationDate = calculateOfferTimerExpirationDate(offer?.enteredDateAdjustedTimeZone);
  return Date.now() >= expirationDate?.getTime();
};

export const calculateOfferTimerExpirationDate = (offerDate: Date) => {
  const offerExpireMinutes = 15;
  const offerExpireDateTime: Date = offerDate
    ? new Date(offerDate.getTime() + (offerExpireMinutes * 60000))
    : null;
  return offerExpireDateTime;
};
