import React from 'react';
import { AvailableLoadSummary } from 'shared/models/loads/load-summaries/available-load-summary.model';
import { ReloadItem } from 'shared/reloads/reload-item/reload-item.component';
import { DataViewFormat } from 'shared/enums/data-view.enum';
import { LazyBoundary } from 'shared/components/lazy/lazy-boundary.component';
import { DataTable } from 'shared/components/data-table/data-table.component';
import { CardView } from 'shared/components/data-table/card-view/card-view.component';
import { useCallback } from 'react';
import { Button } from 'shared/components/elements/button.component';
import { Translation } from 'shared/components/translation/translation.component';

import './reload-results.component.scss';

interface Props {
  reloads: AvailableLoadSummary[];
  onReloadClick?: (e: React.MouseEvent, selectedReload?: AvailableLoadSummary) => void;
  onViewAllClick?: (e: React.MouseEvent) => void;
  callVariation: ReloadsCallVariation;
  primalLoadNumber: number;
  totalReloads: number;
}

function ViewAllButton({
  onViewAllClick,
  callVariation
}: { onViewAllClick: (e: React.MouseEvent) => any, callVariation: ReloadsCallVariation }) {
  return (
    !!onViewAllClick &&
    <span className="view-all-button">
      <Button btnLink onClick={onViewAllClick} track
        id={`view-all-${callVariation.toLowerCase()}-reloads-button`}>
        <Translation resource="VIEW_ALL" />
      </Button>
    </span>);
}

export const ReloadResults = ({
  reloads,
  onReloadClick,
  onViewAllClick,
  callVariation,
  primalLoadNumber,
  totalReloads
}: Props) => {
  const renderCard = useCallback(
    (reload: AvailableLoadSummary) => {
      return <ReloadItem key={reload.number} reload={reload} onReloadClick={onReloadClick}
        callVariation={callVariation} primalLoadNumber={primalLoadNumber} />;
    },
    [onReloadClick, callVariation, primalLoadNumber],
  );

  return (
    !!reloads?.length && <div className="reload-results">
      <LazyBoundary>
        <DataTable
          items={reloads}
          renderFooter={totalReloads > 3 && <ViewAllButton onViewAllClick={onViewAllClick} callVariation={callVariation} />}
          viewFormatOverride={DataViewFormat.Card}
          hideControls={true}
        >
          <CardView renderCard={renderCard} />
        </DataTable>
      </LazyBoundary>
    </div>
  );
};
