import { useTranslation } from 'react-i18next';
import { LoadCommodity } from './load-commodity.component';
import { Button } from 'shared/components/elements/elements.components';
import {CommoditiesModal, Commodities_Modal} from 'shared/components/load-commodity/commodities-modal.component';
import { useDispatcher } from 'app/hooks/store/use-dispatcher.hook';
import { showModal } from 'shared/components/modal/modal.actions';

import './commodities.component.scss';

interface OwnProps {
  items: AvailableLoadItemJSON[];
}

export const Commodities= ({ items }: OwnProps) => {
  const showCommoditiesModal = useDispatcher(showModal);
  const { t } = useTranslation();

  return (
    <div className="commodity-section">
      <div className="separator-line" />
      <div className="title" role="heading" aria-level={3}>
        {t('COMMODITY')}
      </div>
      <LoadCommodity commodities={items}
      />
      {items.length > 1 && (
        <Button
          track
          btnLink
          id="commodity-view-all-link"
          onClick={() => showCommoditiesModal(Commodities_Modal)}
        >
          {t('VIEW_ALL')}
        </Button>
      )}
      <CommoditiesModal commodities={items} />
    </div>
  );
};
