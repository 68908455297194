import { useCallback } from "react";
import { AvailableLoadSummary } from 'shared/models/loads/load-summaries/available-load-summary.model';
import { ReloadEventData } from '../redux/analytics-v2.actions';
import { useAnalyticsTrack } from '@chr/react-analytics';
import { useAnalyticsEventMetaData } from '@features/analytics/hooks/use-analytics-event-metadata.hook';
import { useSearchEvents, useSortActionEvents } from '@features/analytics/redux/analytics-v2.selectors';
import { mapToAdobeSearchCriteria, mapToAdobeLoadDetail } from '@features/analytics/utils/adobe-search-events-data-mapper';
import { generateEventSequence } from '@features/analytics/utils/event-sequence-generator';
import { EVENT_TYPES } from '@features/analytics/constants';

export const useOfferAnalytics = () => {
  const track = useAnalyticsTrack();
  const eventMetaData = useAnalyticsEventMetaData();
  const { sortBy, sortOrder } = useSortActionEvents();
  const { searchCriteria, searchCorrelationId } = useSearchEvents();

  const trackOfferEvent = useCallback((eventType: EVENT_TYPES, load: AvailableLoadSummary, position?: number, offerData?: OfferData, reloadData?: ReloadEventData, statusCode?: number) => {
    const { timeGMT, sequenceNumber } = generateEventSequence();
    const search = mapToAdobeSearchCriteria(searchCriteria);

    const data: KeyValuePair = {
      ...eventMetaData,
      load: mapToAdobeLoadDetail(position, load),
      searchCorrelationId,
      offerId: offerData?.offerId,
      offerRate: offerData?.offeredAmount,
      statusCode,
      sortBy,
      sortType: sortOrder,
      search,
      sequence: {
        isInfinityWorkflow: true,
        timeGMT,
        sessionEventSequenceNumber: sequenceNumber,
      },
      reload: reloadData,
      isCounterOffer: offerData?.isCounterOffer,
      offerData
    };

    track({ event: eventType, ...data });

  }, [track, eventMetaData, sortBy, sortOrder, searchCriteria, searchCorrelationId]);

  return trackOfferEvent;
};
