import { AvailableLoadSummary } from 'shared/models/loads/load-summaries/available-load-summary.model';
import { Currency } from 'shared/components/formatters/currency.formatter';
import { OfferStatus } from 'pages/find-loads-ver2/offers/offer-status.component';
import { useTranslation } from 'react-i18next';
import { useTimer } from 'app/hooks/use-timer.hook';
import { useCarrierOffer } from 'pages/find-loads-ver2/hooks/use-carrier-offer.hook';
import { useIsBookUnavailable } from 'pages/find-loads-ver2/hooks/use-is-book-unavailable.hook';
import { CapLocked } from '@pages/find-loads-ver2/results/cap-restricted/cap-locked.component';

export interface FindLoadResultActionsProps {
  load: AvailableLoadSummary;
  sectionName: string;
}

export const LoadActions = ({ load, sectionName }: FindLoadResultActionsProps) => {
  const { t } = useTranslation();
  const isLoadBookUnavailable = useIsBookUnavailable(load);
  const offerState = useCarrierOffer(load);
  const { carrierOffer, offerStatus, availableLoadOfferAction, bookPrice } = offerState;

  const timeLeft: string = useTimer({ timerExpirationDate: offerStatus?.offerExpirationDate });

  const rate = bookPrice?.value;
  const currencyCode = bookPrice?.currencyCode;
  const hasValidRate: boolean = rate > 0 && !!currencyCode;
  const offer = isLoadBookUnavailable ? null : carrierOffer?.latestOffer;
  const labelResource: ResourceKey = availableLoadOfferAction?.resourceKey;
  const showTimer = !offerStatus?.isOfferExpired && timeLeft && (offerStatus?.isOfferAcceptedByDataScience || offerStatus?.isOfferCountered);

  return (
    <div id={`${sectionName}-action_load-${load.number}`} className="action-area" data-testid="action-area">
      {!offer ? <div></div>
        : <div id={`${sectionName}-action_offer-status-${load.number}`} className="js-offer-status" data-testid="offer-status">
          <div className="sr-only">{t('STATUS')}</div>
          <OfferStatus offerState={offerState} />
        </div>}
      {hasValidRate &&
        <div id={`${sectionName}-action_load-rate-${load.number}`} className="js-load-rate" data-testid="load-rate">
          <div className="sr-only">{t('RATE')}</div>
          <h4>
            <Currency value={rate} currency={currencyCode} />
          </h4>
        </div>
      }
      <div>
        <label className="js-primary-action" aria-label={t('ACTION')} id={`${sectionName}-action_button-${load.number}`} data-testid="action-button">
          <span>
            {showTimer && <span className="time-left" data-testid="time-left">{timeLeft} min </span>}
            {availableLoadOfferAction?.isCapLocked
              ? <CapLocked carrierTier={load.carrierTier} variant={availableLoadOfferAction?.isCapRestricted ? 'restricted' : 'locked'} />
              : t(labelResource)}
          </span>
        </label>
      </div>
    </div>
  );
};

export default LoadActions;
