import React from 'react';
import { Popover } from 'shared/components/popover/popover.component';
import { Translation } from 'shared/components/translation/translation.component';
import { ReloadsData } from 'pages/find-loads-ver2/hooks/use-reloads.hook';

interface ReloadsPopoverProps {
  reloadsData: ReloadsData;
}

export const ReloadsPopover: React.FC<ReloadsPopoverProps> = ({reloadsData}) => {
  const showPopover = !!reloadsData && !!reloadsData.reloads?.length &&
    reloadsData.searchRadius != null &&
    !!reloadsData.origin &&
    !!reloadsData.destination &&
    !!reloadsData.requestTime;

  if (!showPopover) {
    return null;
  }
  return (
    <span className="reloads-more-info-popover">
      <Popover
        content={
          <Translation resource="RELOADS_MORE_INFO_POPOVER"
                       replacements={[
                         reloadsData?.searchRadius,
                         reloadsData.origin,
                         reloadsData.destination,
                         reloadsData.requestTime]}/>}
        triggerClassName="btn btn-clear Popover-trigger"
        isNonButtonTrigger={true}
        preferPlace="above"
        popoverClassName="reloads-more-info-popover-body"
        announceToScreenReader="OPEN_POP_OVER_DIALOG"
        trigger={
          <div>
            <img src="/assets/images/info-circle.svg" className="reloads-more-info-popover-icon"/>
          </div>
        }
      />
    </span>);
};
