import { lazy } from 'react';
import { useWindowSize } from 'shared/components/azure-map/hooks/use-window-size.hook';
import { AvailableLoadStop } from 'shared/models/loads/stops/available-load-stop.model';

const MapDataProvider = lazy(() => import('shared/components/azure-map/load-map-data-context/load-map-data-provider')
  .then(module => ({ default: module.LoadMapDataProvider })));

const LoadMap = lazy(() => import('./load-map/load-map.component')
  .then(module => ({ default: module.LoadMap })));

const LoadMapModal = lazy(() => import('./load-map/load-map-modal.component')
  .then(module => ({ default: module.LoadMapModal })));

export interface LoadMapProps {
  loadNumber: number;
  stops?: AvailableLoadStop[];
};

export const LoadMapComponent = ({ loadNumber, stops }: LoadMapProps) => {
  const windowSize = useWindowSize();

  return (
    <MapDataProvider stops={stops}>
      <LoadMap
        loadNumber={loadNumber}
        minScreenSize={852}
        nonInteractive={true}
        classList={'details-pane'}
        mapKey={'find-loads-details-panel-map'}
        windowSize={windowSize}
      />
      <LoadMapModal
        loadNumber={loadNumber}
        mapKey={'find-loads-details-modal-map'}
        windowSize={windowSize}
      />
    </MapDataProvider>
  );
};