import { ReactNode } from 'react';

interface OwnProps {
    name: string;
    hide?: boolean;
    children?: ReactNode;
}

export const LoadRequirement = ({ name, hide, children }: OwnProps) => {

  const labelId = `requirement-label_${name}`;

  return !hide && (
    <div className="requirement">
      <div id={labelId} className="requirement-label text-left" data-testid={labelId}>
        {name}
      </div>
      <div className="value text-left" aria-labelledby={labelId}>
        {children}
      </div>
    </div>
  );
}
