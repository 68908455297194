import { useTranslation } from 'react-i18next';
import { ShipmentNotes } from './shipment-notes';

import './shipment-notes.component.scss';

interface ShipmentNotesComponentProps {
  notes: shipmentNoteJSON[];
}

export function ShipmentNotesComponent({ notes }: ShipmentNotesComponentProps) {
  const { t } = useTranslation();
  const hasNotes = notes.length > 0;

  return (
    <div className="notes-section-preview">
      <div className="label">{t('NOTES')}</div>
      {hasNotes ?
        (<>
          <div className="shipment-note-content">
            <ShipmentNotes notes={notes} />
          </div>
        </>)
        :
        <p className="shipment-note" data-test="no-notes">{t('THERE_ARE_NO_NOTES_TO_DISPLAY')}</p>
      }
    </div>
  );
}
