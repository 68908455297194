import { useTranslation } from 'react-i18next';
import { IsCarrierRestrictedOnLoad } from 'app/util/loads/cap-restricted-function';
import { CarrierTier } from 'shared/enums/carrier-tier.enum';
import { useCarrierDetails } from 'app/hooks/store/use-carrier-details.hook';

import './cap-label.component.scss';

interface Props {
  loadTier: string;
}

export const CapLabel = ({ loadTier }: Props) => {
  const { t } = useTranslation();
  const carrierCapCode = useCarrierDetails()?.capCode;
  const upperLoadTier = (loadTier ?? 'None').toUpperCase();

  if (IsCarrierRestrictedOnLoad(upperLoadTier, carrierCapCode) || ![CarrierTier.KEY, CarrierTier.CORE].includes(CarrierTier[upperLoadTier])) {
    return;
  }

  const isKeyLoad: boolean = upperLoadTier === CarrierTier.KEY;

  return <div className={`${upperLoadTier} cap-label`} data-testid="cap-label">
    <img className="cap-icon" src={isKeyLoad ? "/assets/images/key_orange.svg" : "/assets/images/stars_blue.svg"} data-testid="cap-icon" />
    <span>{t(isKeyLoad ? 'KEY_EXCLUSIVE' : 'CORE_EXCLUSIVE')}</span>
  </div>;
};
