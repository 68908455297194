import { Translation, TranslationJSX } from 'shared/components/translation/translation.component';

interface LoadCommodityProps {
  commodities: AvailableLoadItemJSON[];
}

export const LoadCommodity = ({ commodities }: LoadCommodityProps) => {
  const firstComponentName = commodities[0]?.commodityName;
  return (
  <div className="commodity-info">
    <div className="commodity-name">
      {firstComponentName ? firstComponentName : <Translation resource="NO_COMMODITY_INFO_AVAILABLE" />}
    </div>
    {commodities.length > 1 && (
      <div className="multiple-commodities">
        <TranslationJSX  resource="PLUS_NUMBER_MORE" replacements={[<span> {commodities.length - 1}</span>]} />
      </div>
    )}
  </div>
)};
